
const bgColor = { backgroundColor: '#0c1934' }
const btnPrimary = "bg-primary inline-flex items-center justify-center rounded-full py-2 px-8 sm:px-12 sm:py-3 text-center text-lg font-normal text-white border-2 border-transparent  tracking-wider transition-all duration-200 sm:w-auto hover:bg-opacity-90 lg:px-10 xl:px-16 disabled:bg-gray-500"


const btnOutline = 'inline-flex items-center justify-center rounded-full py-2 px-8 sm:px-12 text-lg font-normal text-primary transition-all duration-200 border-2 border-primary tracking-wider xl:px-16 sm:w-auto sm:mt-0 sm:py-3 focus:outline-none  hover:bg-primary focus:bg-primary hover:text-white focus:text-white hover:border-primary focus:border-primary'

const btnFull = 'w-full '

const section = 'px-3 py-14 sm:py-24 border-t sm:border-0' // sm:bg-red-600 md:bg-green-600 lg:bg-purple-700  xl:bg-blue-600' 

const countItem = 'flex flex-col w-[70px] text-3xl sm:w-[90px] sm:text-4xl font-extrabold text-gray-900 py-2 px-0 sm:px-4 rounded-md border border-neutral-200 bg-slate-50 drop-shadow-sm'
const spanCount = 'text-base text-gray-500 font-light capitalize'

const input = 'block w-full px-4 py-3 text-gray-900 placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus: outline-none focus:border-secondary caret-secondary placeholder:font-light placeholder:text-md'

export { btnPrimary, btnOutline, section, countItem, spanCount, btnFull, input, bgColor }