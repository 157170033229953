import React, { useState, useEffect, useRef } from 'react'
import { input as cssClass, btnPrimary, section, btnOutline } from '../styles/styles'
import FormField from '../components/form-field'
import useForm from '../hooks/useForm'
import { checkInputs } from '../hooks/useForm/validation'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { Link, navigate } from 'gatsby'


//https://css-tricks.com/user-registration-authentication-firebase-react/

const Login = () => {
    const [formState, setFormState] = useState({ email: '', password: '' })
    const [showMessage, setShowMessage] = useState(false)

    const { values: { email, password }, errors, handleChange, formSubmit, isValid } = useForm(
        handleSubmit,
        // if new record initilState ? the fetched data
        formState
    )

    const inputRef = useRef()
    const [errorsForm, setErrorsForm] = useState(errors)
    useEffect(() => { signOut(auth) }, [])

    async function handleSubmit() {
        inputRef.current.value = ''
        inputRef.current.focus()
        const errs = checkInputs({ email, password })
        setErrorsForm(errs)
        if (Object.keys(errs).length > 0) return
        signInWithEmailAndPassword(auth,
            email, password
        ).then(() => {
            setErrorsForm({})
            setFormState({ email: '', password: '' })
            setShowMessage(false)
            navigate('/admin', { state: { prevPath: '/signin' } })
        }).catch(err => {
            setFormState({ email: '', password: '' })
            setShowMessage(true)
            navigate('/signin')
        })

    }






    return (
        <section className={`${section} h-screen`}>
            <div className="flex  justify-center items-center h-full">
                <div className='w-[450px] bg-white rounded-lg p-10 py-14'>
                    <form action="" onSubmit={formSubmit}>

                        {showMessage && <p className="text-red-800  mt-0 pt-1 text-base">Email / Mot de passe Invalid!</p>}
                        <div className="mt-3 relative">
                            <FormField
                                label='Email'
                                type='email'
                                name='email'
                                classname={cssClass}
                                placeholder='Email'
                                errorMessage={errorsForm['email']}
                                validationRule='isRequired'
                                value={email || ''}
                                handleChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className="mt-3 mb-6 relative">
                            <FormField
                                label='Mot de Passe'
                                type='password'
                                name='password'
                                classname={cssClass}
                                placeholder='Mot de Passe'
                                errorMessage={errorsForm['password']}
                                validationRule='isRequired'
                                value={password || ''}
                                handleChange={handleChange}
                                required={true}
                                reference={inputRef}
                            />
                        </div>
                        <div className='space-x-0 space-y-4 sm:space-y-0 sm:space-x-2 flex  flex-col sm:flex-row sm:justify-between'>

                            <button type='submit' className='bg-primary w-full  text-center text-white rounded-2xl sm:w-[150px] py-3 xl:px-6'>Connexion</button>
                            <Link to='/' className='border border-primary w-full text-center text-primary rounded-2xl sm:w-[150px] py-3 xl:px-6'>
                                Annuler
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Login