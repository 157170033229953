import React from 'react'
import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'

import Login from '../layouts/login'
import { navigate } from 'gatsby'

const Signin = () => {

    return (
        <Login />
    )
}

export default Signin